<!-- 批号修改-新增修改 -->
<template>
  <div class="pagePadding">
    <div class="pageTop">
      <Form inline class="formMarginBtm20">
        <FormItem v-if="!id">
          <span>所属仓库：</span>
          <Select class="iviewIptWidth250 marginRight60" v-model="warehouse_id" filterable clearable @on-select="warehouseChange">
            <Option v-for="(item, index) in storeList" :value="item.warehouse_id" :key="index">{{ item.warehouse_name }}</Option>
          </Select>
        </FormItem>
        <FormItem v-if="!id">
          <span>产品名称：</span>
          <Select class="iviewIptWidth250 marginRight60" @on-change="productChange" :disabled="warehouse_id < 0" v-model="queryFrom.product_id" clearable filterable>
            <Option v-for="(item, index) in productList" :value="item.product_id" :key="index">{{ item.product_name }}</Option>
          </Select>
        </FormItem>
        <FormItem v-if="!id">
          <span>规格型号/SKU：</span>
          <Select class="iviewIptWidth250 marginRight60" :disabled="warehouse_id < 0" multiple filterable clearable v-model="queryFrom.specification_id_str" :max-tag-count="queryFrom.specification_id_str.length == 1 ? 1 : 0">
            <Option v-for="(item, index) in modelList" :value="item.specification_id" :key="index" :label="item.model_name + '-' + item.item_number">
              <span>{{ item.model_name }}</span>
              <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
            </Option>
          </Select>
        </FormItem>
        <FormItem v-if="id">
          <span>审核人：</span>
          <Select placeholder="请选择" clearable class="iviewIptWidth250" filterable v-model="modifier_id" @on-change="userChange" :disabled="id > 0">
            <Option v-for="(item, index) in userList" :value="item.id" :key="index">{{ item.user_name }}</Option>
          </Select>
        </FormItem>
        <!-- 按钮 -->
        <FormItem class="button" :label-width="10" v-if="!id">
          <!-- <span class="longTextBtn finger btnSure" @click="queryProductLookup" loading>库存查询</span> -->
          <Button type="primary" :loading="loading" @click="queryProductpage">
            <span v-if="!loading">库存查询</span>
            <span v-else>Loading...</span>
          </Button>
        </FormItem>
      </Form>
      <Table :productList="listColumns" :productData="meterList" border no-data-text="暂无产品" :style="{ 'min-height': meterList.length == 0 ? '0px' : '400px' }" :loading="loading" :isLoad="loading">
        <template slot-scope="{ row, index }" slot="current_batch_number">
          <i-input placeholder="请输入" :value="row.current_batch_number" @on-change="changeIpt($event, 'current_batch_number', index)"></i-input>
        </template>
        <template slot-scope="{ row, index }" slot="modify_quantity">
          <!--          <i-input placeholder="请输入" :value="row.modify_quantity" @on-change="changeIpt1($event, 'modify_quantity', index)"></i-input>-->
          <InputNumber :max="999999" :min="0" :precision="0" :value="row.modify_quantity" :active-change="false" @on-change="changeTotal($event, 'modify_quantity', index)"></InputNumber>
        </template>
        <template slot-scope="{ row, index }" slot="current_valid_period">
          <Input @on-blur="dataBlur($event)" @on-change="changeIpt($event, 'current_valid_period', index)" type="text" :value="row.current_valid_period" placeholder="请输入" style="width: 200px;" />
          <!-- <DatePicker :editable="false" placeholder="请选择" type="date" format="yyyy-MM-dd" :value="row.current_valid_period" @on-change="timeChange($event, index, 'current_valid_period')"></DatePicker> -->
        </template>
        <template slot-scope="{ row, index }" slot="current_production_date">
          <Input @on-blur="dataBlur($event, true)" @on-change="changeIpt($event, 'current_production_date', index)" type="text" :value="row.current_production_date" placeholder="请输入" style="width: 200px;" />
          <!-- <DatePicker :editable="false" placeholder="请选择" type="date" format="yyyy-MM-dd" :value="row.current_production_date" @on-change="timeChange($event, index, 'current_production_date')"></DatePicker>  -->
        </template>
      </Table>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="meterList.length == 0 && !loading">
        <img style="margin-top: 100px;" src="../../assets/images/tableNoData.png" />
      </div>
      <div class="tipsDiv">
        <div class="tipsFl">备注</div>
        <div class="tipsFr">
          <i-input type="textarea" maxlength="100" show-word-limit placeholder="请输入" v-model.trim="note"></i-input>
        </div>
      </div>
    </div>
    <div class="pageBtm">
      <span class="pageBtn finger btnSure fr marginLeft20" @click="post" v-if="!id">提交</span>
      <span class="pageBtn finger btnReset fr" @click="back">返回</span>
    </div>

    <!-- 查询库存 -->
    <Modal v-model="inventory" :mask-closable="false" width="1260" class="modal" title="产品列表">
      <Table ref="customTable" :productList="inventoryColumns" :productData="inventoryData" border @on-select="selectChange" @on-select-cancel="cancelSelectChange" @on-select-all="selectChangeAll" @on-select-all-cancel="cancalSelectChangeAll" :total="total" :pages="pages" @change-page="changePage" totalText="条记录" width="550" height="548"></Table>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="sureChoose">确定</span>
      </div>
    </Modal>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--    提交-->
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="surePost"></tips-component>
  </div>
</template>

<script>
import Table from '../../components/table.vue'
import TipsComponent from '@/components/tipsComponent'
export default {
  name: 'addChangeBatch1',
  components: {
    Table,
    TipsComponent,
  },
  data() {
    return {
      total: null,
      loading: false,
      note: '',
      modifier_id: '',
      modifier: '',
      deleteStatus: false,
      postStatus: false,
      inventory: false,
      inventoryColumns: [
        {
          type: 'selection',
          width: 30,
          align: 'center',
        },
        {
          title: '物料号',
          key: 'product_model_code',
          align: 'center',
          width: 130,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          width: 170,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 90,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 90,
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          width: 120,
        },
        {
          title: '供货单价',
          key: 'unit_price',
          align: 'center',
          width: 90,
        },
        {
          title: '数量',
          key: 'inventory_quantity',
          align: 'center',
          width: 50,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 60,
        },
        {
          title: '包装规格',
          key: 'packing_size',
          align: 'center',
          width: 90,
        },
      ],
      inventoryData: [],
      listData: [],
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '产品编号',
          key: 'product_model_code',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 180,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          minWidth: 180,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '生产厂家',
          key: 'factory_name',
          align: 'center',
          minWidth: 180,
        },
        {
          title: '条形码',
          key: 'bar_code',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 60,
        },
        {
          title: '库存数量',
          key: 'inventory_quantity',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '原批号',
          key: 'batch_number',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '原生产日期',
          key: 'productionDate',
          align: 'center',
          minWidth: 110,
          render: (h, param) => {
            return h('div', (param.row.productionDate = param.row.production_date ? this.$utils.Timetransformation(this.$moment(param.row.production_date * 1000).format('YYYY-MM-DD HH:mm:ss')) : ''))
          },
        },
        {
          title: '原效期',
          key: 'validPeriod',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            return h('div', (param.row.validPeriod = param.row.valid_period ? this.$utils.Timetransformation(this.$moment(param.row.valid_period * 1000).format('YYYY-MM-DD HH:mm:ss')) : ''))
          },
        },
        {
          title: '现批号',
          slot: 'current_batch_number',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '现生产日期',
          slot: 'current_production_date',
          width: 140,
          align: 'center',
        },
        {
          title: '现效期',
          slot: 'current_valid_period',
          width: 140,
          align: 'center',
        },
        {
          title: '修改数量',
          slot: 'modify_quantity',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '操作',
          align: 'center',
          width: 60,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',

                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.deleteRow(param.index)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      queryFrom: {
        supplier_id: '',
        product_id: '',
        specification_id_str: '',
      },
      supplierId: '',
      productList: [],
      modelList: [],
      warehouse_id: '-1',
      storeList: [],
      userList: [],
      meterList: [], // 已选产品列表
      deleteIndex: 0,
      id: '',
      pages: {
        page: 1,
        rows: 10,
      },
      hadSelectList: [],
      isAll: false,
    }
  },
  mounted() {
    this.queryStoreList()
    this.specialUser()
    this.id = this.$route.query.id
    if (this.id) {
      this.queryDetail()
    }
  },
  methods: {
    queryProductpage() {
      this.pages.page = 1
      this.hadSelectList = []
      this.queryProductLookup()
    },
    changePage(e) {
      this.pages.page = e
      this.queryProductLookup()
    },
    dataBlur(e, type) {
      if (!e.target.value && type) return
      let res = this.$utils.dataType(e.target.value)
      if (res.code) {
        this.$Message.warning(res.text)
      }
    },
    back() {
      this.$router.go(-1)
    },
    queryDetail() {
      this.loading = true
      this.$http.get(this.$api.batchModifyDetail, { id: this.id }, true).then(res => {
        this.meterList = res.data.line_info
        this.modifier_id = res.data.reviewer_id
        this.note = res.data.note
        this.loading = false
        for (let i = 0; i < this.meterList.length; i++) {
          // this.meterList[i].validPeriod = this.meterList[i].valid_period ? this.$moment(this.meterList[i].valid_period * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
          // this.meterList[i].validPeriod = this.$utils.Timetransformation(this.meterList[i].validPeriod)
          // this.meterList[i].productionDate = this.meterList[i].production_date ? this.$moment(this.meterList[i].production_date * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
          // this.meterList[i].productionDate = this.$utils.Timetransformation(this.meterList[i].productionDate)
          // 判断是录入的是年月还是年月日
          this.meterList[i].current_production_date = this.meterList[i].current_production_date ? this.$moment(this.meterList[i].current_production_date * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
          this.meterList[i].current_production_date = this.meterList[i].current_production_date ? this.$utils.Timetransformation(this.meterList[i].current_production_date) : null
          this.meterList[i].current_valid_period = this.meterList[i].current_valid_period ? this.$moment(this.meterList[i].current_valid_period * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
          this.meterList[i].current_valid_period = this.meterList[i].current_valid_period ? this.$utils.Timetransformation(this.meterList[i].current_valid_period) : null
        }
      })
    },
    userChange(e) {
      for (let i = 0; i < this.userList.length; i++) {
        if (e == this.userList[i].id) {
          this.modifier = this.userList[i].user_name
        }
      }
    },
    surePost() {
      let data = {
        modifier_id: this.modifier_id,
        note: this.note,
        line_info: this.meterList,
        modifier: this.modifier,
      }
      this.$http.post(this.$api.batchModify, data, false).then(res => {
        this.$Message.success('提交成功')
        this.$router.go(-1)
      })
      sessionStorage.setItem('updataCache', '0')
    },
    // 提交
    post() {
      // if (!this.modifier_id) {
      //   this.$Message.warning('请选择审核人')
      //   return
      // }
      for (let i = 0; i < this.meterList.length; i++) {
        if (!this.meterList[i].current_batch_number || !this.meterList[i].current_valid_period || !this.meterList[i].modify_quantity) {
          this.$Message.warning(`请完善第${i + 1}组产品的现批号/现有效期/修改数量再提交`)
          return
        }
        if (!this.meterList[i].current_production_date) {
          this.meterList[i].current_production_date = null
        }
        // let resCode = this.$utils.dataType(this.meterList[i].current_production_date)
        // if (resCode.code) {
        //   this.$Message.warning(resCode.text)
        //   return
        // }
        let resCode2 = this.$utils.dataType(this.meterList[i].current_valid_period)
        if (resCode2.code) {
          this.$Message.warning(resCode2.text)
          return
        }
      }
      this.postStatus = true
    },
    // 确认删除
    sureDelete() {
      this.meterList.splice(this.deleteIndex, 1)
      this.deleteStatus = false
      this.$Message.success('删除成功')
    },
    // 删除
    deleteRow(index) {
      this.deleteIndex = index
      this.deleteStatus = true
    },
    timeChange(e, index, name) {
      this.$set(this.meterList[index], name, e)
    },
    // 确认选择 关闭弹窗
    sureChoose() {
      if (this.hadSelectList.length == 0) {
        this.$Message.warning('请至少选择一个产品')
        return
      }
      this.inventory = false
      if (this.meterList.length > 0) {
        let select = this.meterList.map(item => item.product_id + item.specification_id)
        this.hadSelectList = this.hadSelectList.filter(i => {
          return !select.includes(i.product_id + i.specification_id)
        })
        let arr = [...this.meterList, ...this.hadSelectList]
        this.meterList = JSON.parse(JSON.stringify(arr))
      } else {
        this.meterList = JSON.parse(JSON.stringify(this.hadSelectList))
        for (let i = 0; i < this.meterList.length; i++) {
          let num = this.meterList[i].modify_quantity ? this.meterList[i].modify_quantity : 0
          this.$set(this.meterList[i], 'modify_quantity', num)
          this.meterList[i].validPeriod = this.meterList[i].valid_period ? this.$moment(this.meterList[i].valid_period * 1000).format('YYYY-MM-DD') : ''
          this.meterList[i].productionDate = this.meterList[i].production_date ? this.$moment(this.meterList[i].production_date * 1000).format('YYYY-MM-DD') : ''
        }
      }
    },
    // 选择数据发生改变时
    selectChange(list, row) {
      console.log(row, this.hadSelectList)
      this.hadSelectList.push(row)
    },
    // 取消某一项是
    cancelSelectChange(list, row) {
      let foo
      this.hadSelectList.forEach((item, index) => {
        if (item.id == row.id) {
          foo = index
        }
      })
      // 删除勾选项
      this.hadSelectList.splice(foo, 1)
      // 如果 暂存勾选项 的长度等于 出库单列表 的长度则勾选表头 全选
      this.isAll = this.hadSelectList.length == this.inventoryData
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 点击全选时
    selectChangeAll() {
      this.getAllData()
    },
    // 取消全选
    cancalSelectChangeAll() {
      this.hadSelectList = []
    },
    // 拉取全数据
    getAllData() {
      let query = {
        warehouse_id: this.warehouse_id,
        product_id: this.queryFrom.product_id,
        specification_id_str: this.queryFrom.specification_id_str ? this.queryFrom.specification_id_str.join(',') : '',
        lookup: '1',
        page: this.pages.page,
        rows: 10000,
      }
      this.$http.get(this.$api.inventoryProductLookup, query, false).then(res => {
        if (res.data.length > 0) {
          this.hadSelectList = res.data
        }
      })
    },
    // 点击查询按钮 查询弹窗
    queryProductLookupAll() {
      let query = {
        warehouse_id: this.warehouse_id,
        product_id: this.queryFrom.product_id,
        specification_id_str: this.queryFrom.specification_id_str ? this.queryFrom.specification_id_str.join(',') : '',
        lookup: '1',
        page: this.pages.page,
        rows: 10000,
      }
      this.$http.get(this.$api.inventoryProductLookup, query, false).then(res => {
        if (res.data.length > 0) {
          this.hadSelectList = []
          this.hadSelectList = res.data
        } else {
          this.$Message.warning('查询暂无数据')
        }
      })
    },
    // 点击查询按钮 查询弹窗
    queryProductLookup() {
      if (!this.warehouse_id) {
        this.$Message.warning('请先选择仓库名称后再查询')
        return
      }
      this.loading = true
      let query = {
        warehouse_id: this.warehouse_id,
        product_id: this.queryFrom.product_id,
        specification_id_str: this.queryFrom.specification_id_str ? this.queryFrom.specification_id_str.join(',') : '',
        lookup: '1',
        page: this.pages.page,
        rows: this.pages.rows,
      }
      this.$http.get(this.$api.inventoryProductLookup, query, false).then(res => {
        if (res.data.length > 0) {
          // this.hadSelectList = []
          this.inventoryData = res.data
          this.total = res.total
          this.inventory = true
          this.inventoryData.forEach((item, index) => {
            this.hadSelectList.forEach(item2 => {
              if (item.id == item2.id) {
                this.inventoryData[index]._checked = true
              }
            })
          })
        } else {
          this.$Message.warning('查询暂无数据')
        }
        this.loading = false
      })
    },
    // 客户名称改变
    warehouseChange(e) {
      if (e.value) {
        this.queryFrom.product_id = ''
        this.queryFrom.specification_id_str = []
        this.saveIndex = e.value
        this.warehouse_id = e.value
        this.queryProduct(e.value, null)
      } else {
        this.queryFrom.product_id = ''
        this.queryFrom.specification_id_str = []
      }
    },
    // 查询产品
    queryProduct(id = null, subId = null) {
      if (this.warehouse_id) {
        this.$http.get(this.$api.inventoryProductLookup, { product_id: subId, warehouse_id: this.warehouse_id }, false).then(res => {
          // if (!subId) {
          this.productList = res.data
          // } else {
          this.modelList = res.data
          // }
        })
      }
    },
    // 产品名称改变
    productChange(e) {
      this.queryFrom.specification_id_str = []
      // if (!e) return
      this.modelList = []
      this.queryProduct(this.warehouse_id, e)
    },
    // 查询仓库列表
    queryStoreList() {
      this.isLoad = false
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
      }
      this.$http.get(this.$api.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    // 审核人
    specialUser() {
      this.$http.get(this.$api.publicSpecialUser, { user_type: '2' }, true).then(res => {
        this.userList = res.data
      })
    },
    // 输入框改变
    changeIpt(e, name, index) {
      this.$set(this.meterList[index], name, e.target.value.trim())
    },
    changeTotal(e, name, index) {
      this.$set(this.meterList[index], name, e * 1)
    },
    changeIpt1(e, name, index) {
      if (e.target.value * 1 < 0) {
        this.$set(this.meterList[index], name, 1)
        return null
      } else {
        this.$set(this.meterList[index], name, e.target.value.trim() * 1)
        return null
      }
    },
  },
}
</script>

<style scoped lang="less">
/deep/ .ivu-modal {
  width: 1260px !important;
}
.marginRight60 {
  margin-right: 50px;
}
.pagePadding {
  display: flex;
  flex-direction: column;

  .pageTop {
    flex: 1;
    overflow-y: auto;
    .tipsDiv {
      margin-top: 20px;
      border: 1px solid #e8eaec;
      height: 230px;
      display: flex;

      .tipsFl {
        width: 210px;
        line-height: 230px;
        padding-left: 33px;
        border-right: 1px solid #e8eaec;
        background: #f8f8f9;
        color: #525b6d;
        font-size: 14px;
      }

      /deep/ .tipsFr {
        flex: 1;
        display: flex;
        padding: 8px 15px 8px 23px;

        .ivu-input-wrapper {
          height: 100% !important;
        }

        .ivu-input {
          height: 100% !important;
        }
      }
    }
  }

  .pageBtm {
    text-align: right;
    padding-top: 25px;
  }
}

.modal {
  .header {
    font-size: 18px;
    height: 25px;
    line-height: 25px;
    font-weight: 500;
    color: #333333;
  }

  .pageBtn {
    width: 120px;
    margin-bottom: 40px;
  }
}
.marginLeft77 {
  margin-left: 73px;
}
.marginLeft60 {
  margin-left: 60px;
}
.marginTop20 {
  margin-top: 20px;
  height: 30px;
}
</style>
